/* You can add global styles to this file, and also import other style files */
@import './assets/sass/style.scss';
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import './assets/sass/style.angular.scss';

// @vanchuong201 add font ki
@import './assets/plugins/keenicons/solid/style.css';
@import './assets/plugins/keenicons/outline/style.css';

// toastr
@import 'ngx-toastr/toastr';
@import 'ngx-toastr/toastr-bs5-alert';

// import variables custom
@import './assets/sass/components/variables.custom';

/*
* Custom styles
*/

/*Begin::Font*/
.fs-lg {
  font-size: $font-size-lg !important; // 16px
}

.fs-sm {
  font-size: $font-size-sm !important; // 13px
}

/*End::Font*/

/*Begin::Button*/
.btn {
  line-height: 1.3334rem !important; // 20px
  font-size: 1rem !important; // 15px
  padding: 0.8rem 1.2rem !important; // 10px 18px

  &.btn-outline {
    padding: calc(0.8rem - 1px) calc(1.2rem - 1px) !important; // 10px 18px

    &.btn-sm {
      padding: calc(0.666667rem - 1px) calc(1rem - 1px) !important; // 8px 15px
    }

    &.btn-lg {
      padding: calc(1.0667rem - 1px) calc(1.6rem - 1px) !important; // 12px 18px
    }
  }

  &.btn-icon {
    height: 44px;
    width: 44px;
  }

  &.btn-lg {
    line-height: 1.6rem !important; // 24px
    font-size: 1.0667rem !important; // 16px
    padding: 1.0667rem 1.6rem !important; // 16px 24px

    &.btn-icon {
      height: 56px;
      width: 56px;
    }
  }

  &.btn-sm {
    line-height: 1.06667rem !important; // 16px
    font-size: 0.866667rem !important; // 13px
    padding: 0.666667rem 1rem !important; // 10px 15px

    &.btn-icon {
      height: 36px;
      width: 36px;
    }
  }

  // custom
  &.btn-active-success-emphasis {
    &:hover, &.active{ // 146C43FF
      color: var(--bs-success-inverse);
      border-color: #146C43FF;
      background-color: #146C43FF !important;
    }
  }
}

/*End::Button*/

/*Start::Form Input / Label*/
.form-control {
  border-color: $gray-500;

  &:hover {
    border-color: $gray-600;
  }

  padding: 0.5753rem 1rem;

  &.form-control-sm {
    padding: .63334rem 1rem;
    //font-size: $font-size-base;
    line-height: 1;
  }

  &.form-control-lg {
    padding: 0.9rem 1rem;
    font-size: 1.2rem;
  }

  &.form-control-table-editor {
    font-size: 1rem !important;
    min-height: 30px !important;
    height: 36px !important;
  }
}

.input-group-text,
.form-check-input {
  border-color: $gray-500;
}

.form-select {
  border-color: $gray-500;

  &:hover {
    border-color: $gray-600;
  }

  padding-top: 0.5753rem;
  padding-bottom: 0.5753rem;

  &.form-select-sm {
    padding: .63334rem 1rem;
    font-size: .733334rem;
    line-height: 1;
  }

  &.form-select-lg {
    padding: 0.9rem 1rem;
    font-size: 1.2rem;
  }
}

.form-label {
  font-weight: 600;
  color: $gray-900;
}

.form-check-label {
  color: $gray-700;
}

.form-check-rounded {
  .form-check-input {
    border-radius: 100%;
  }
}

/*End::Form Input / Label*/

/*Begin::Checkbox & Radio*/
.form-check-input {
  height: 1.2rem;
  width: 1.2rem;

  &.form-check-lg {
    height: 1.6rem;
    width: 1.6rem;
  }

  &.form-check-sm {
    height: 1rem;
    width: 1rem;
  }
}

/*End::Checkbox & Radio*/

/*Begin::Custom ng-select*/
ng-select {
  &.ng-select-multiple {
    .ng-value-container {
      .ng-placeholder {
        margin-top: 0.4rem;
      }
    }
  }

  .ng-select-container {
    height: 44px !important;
    border-radius: 0.475rem !important;
    border-color: $gray-500 !important;
  }

  &.ng-select-lg {
    .ng-select-container {
      height: 56px !important;
    }
  }

  &.ng-select-sm {
    .ng-select-container {
      height: 36px !important;
    }
  }

  &.ng-select-table-editor {
    .ng-select-container {
      height: 30px !important;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border-color: $gray-400 !important;
    }
  }

  &.criterion-select {
    .ng-value {
      width: 100%;
    }
  }
  ///
}

/*End::Custom ng-select*/



/*Begin::Card*/
.card-custom {
  border: 1px solid $gray-300 !important;
  border-radius: .475rem;

  .card-header {
    padding: 0 25px;

    .card-title {
      font-weight: 700;
      font-size: 1.33333rem;
      color: var(--bs-primary);
    }
  }

  .card-body {
    padding: 0 25px 25px;
  }
}

/*End::Card*/

/*Begin::Table*/
.table {
  &:not(.table-bordered) {

    th:first-child,
    td:first-child {
      padding-left: 0 !important;
    }
  }

  &.table-custom {
    thead {
      th {
        vertical-align: middle !important;
        font-size: $font-size-base !important;
        font-weight: 600;
        color: $info !important;
      }
    }

    tbody {
      td {
        vertical-align: middle !important;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }

  .table-vertical-middle {
    td {
      vertical-align: middle !important;
    }
  }

  &.table-bordered {
    border-color: $gray-300 !important;

    td {
      padding-left: .5rem;
    }

    tr,
    th,
    td {
      border-width: 1px;
      border-style: solid;
      border-color: $gray-300;
    }
  }

}

/*End::Table*/

/*Begin::Nav*/
.nav-tabs {
  border-color: $gray-300;

  .nav-link {
    border-color: var(--bs-gray-300);

    &.active {
      border-color: var(--bs-gray-300);
      border-bottom: none;
    }
  }
}

/*End::Nav*/

/*Begin::Form Validator*/

.form-control {
  &.ng-invalid.ng-touched {
    border-color: var(--bs-danger);
    padding-right: calc(1.5em + 1.55rem);
    background-position: top calc(0.375em + 0.3875rem) right calc(0.375em + 0.3875rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f1416c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f1416c' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
  }
}

ng-select {
  &.ng-invalid.ng-touched {
    .ng-select-container {
      border-color: var(--bs-danger) !important;
      padding-right: calc(1.5em + 1.55rem);
      background-position: top calc(0.375em + 0.3875rem) right calc(0.375em + 0.3875rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f1416c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f1416c' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
    }
  }
}
/*End::Form Validator*/


/*Begin::Toastr*/
.toast-message {
  font-size: 1rem;
  font-weight: 400;
  color: $white;
}
/*End::Toastr*/



/*Begin::Other*/
.d-content {
  display: contents !important;
}

@for $line from 1 through 10 {
  .max-line-#{$line} {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: #{$line};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fill-level-1 {
  fill: #FF170B
}

.fill-level-2 {
  fill: #FF8F0B;
}

.fill-level-3,
.fill-level-4 {
  fill: #23ACCA;
}

.fill-level-5 {
  fill: #3A974C;
}

.bg-level-1.active {
  background-color: #FF170B;
  color: white;
}

.bg-level-2.active {
  background-color: #FF8F0B;
  color: white;
}

.bg-level-3.active,
.bg-level-4.active {
  background-color: #23acca;
  color: white;
}

.bg-level-5.active {
  background-color: #3A974C;
  color: white;
}

.bg-level-1 {
  color: #FF170B;
  border-color: #FF170B !important;
}

.bg-level-2 {
  color: #FF8F0B;
  border-color: #FF8F0B !important;
}

.bg-level-3,
.bg-level-4 {
  color: #23acca;
  border-color: #23acca !important;
}

.bg-level-5 {
  color: #3A974C;
  border-color: #3A974C !important;
}

.min-w-0 {
  min-width: 0;
}

.min-w-96 {
  min-width: 96px;
}

/*End::Other*/
