//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//


// Typography
// Font
$font-family-sans-serif: 'Nunito', sans-serif;

$font-size-base: 1rem !default; // 15px
$font-size-lg: $font-size-base * 1.06667;   // 16px
$font-size-sm: $font-size-base * .86667;   // 13px

$h1-font-size:  $font-size-base * 1.86667; // 28px
$h2-font-size:  $font-size-base * 1.6; // 24px
$h3-font-size:  $font-size-base * 1.33333; // 20px
$h4-font-size:  $font-size-base * 1.2; // 18px
$h5-font-size:  $font-size-base * 1.1333; // 17px
$h6-font-size:  $font-size-base * 1.06667; // 16px

$font-sizes: (
  1: $h1-font-size,   // 28px
  2: $h2-font-size,   // 24px
  3: $h3-font-size,   // 20px
  4: $h4-font-size,   // 18px
  5: $h5-font-size,   // 17px
  6: $h6-font-size,   // 16px

  7: $font-size-base * 0.95,  // 14.25px
  8: $font-size-base * 0.85,  // 12.75px
  9: $font-size-base * 0.75,  // 11.25px
  10: $font-size-base * 0.5,  // 7.5px

  base: $font-size-base, // 15px
  fluid: 100%, // 100%

  2x: $font-size-base * 2,  // 30px
  2qx: $font-size-base * 2.25, // 33.75px
  2hx: $font-size-base * 2.5,  // 37.5px
  2tx: $font-size-base * 2.75, // 41.25px

  //3x: $font-size-base * 3,  // 45px
  //3qx: $font-size-base * 3.25, // 48.25px
  //3hx: $font-size-base * 3.5,  // 51.5px
  //3tx: $font-size-base * 3.75, // 54.75px
  //
  //4x: $font-size-base * 4,  // 60px
  //4qx: $font-size-base * 4.25, // 63.25px
  //4hx: $font-size-base * 4.5,  // 66.5px
  //4tx: $font-size-base * 4.75, // 69.75px
  //
  //5x: $font-size-base * 5,  // 75px
  //5qx: $font-size-base * 5.25, // 78.25px
  //5hx: $font-size-base * 5.5,  // 81.5px
  //5tx: $font-size-base * 5.75  // 84.75px
);

// Theme colors
// Primary
$primary:       									#EA430D;
$primary-active:    								#C12F00;
$primary-light:    								    #FFEDE7;
$primary-light-dark:                    #781e00;
$primary-inverse:  									   #ffffff;

// Success
$success:       									#50cd89;
$success-active:    								#47be7d;
$success-light:    									#e8fff3;
$success-light-dark:    							#1c3238;
$success-inverse:  									#ffffff;


//$success:       									#166534;
//$success-active:    								#14532D;
//$success-light:    									#DCFCE7;
//$success-light-dark:    							#1c3238;
//$success-inverse:  									#ffffff;

// Info
$info:       									    #605BFF;
$info-active:    									#3732ff;
$info-light:    									#f8f5ff;
$info-light-dark:                 #2f264f;
$info-inverse:  									#ffffff;

// Danger
$danger:       									    #f1416c; //FF170B
$danger-active:    									#d9214e; //DC2626;
$danger-light:    									#fff5f8; //FEF2F2;
$danger-light-dark:    								#3a2434;
$danger-inverse:  									#ffffff;

// Warning
$warning:       									#D97706; // #FBBF24
$warning-active:    							#f1bc00	; // #F59E0B
$warning-light:    								#fff8dd	; // #FEF3C7
$warning-light-dark:    							#392f28;
$warning-inverse:  									#ffffff;

// Card Box Shadow
$card-box-shadow:                                   0px 0px 20px 0px rgba(76,87,125,0.02);
$card-box-shadow-dark:                              0px 0px 20px 0px rgba(76,87,125,0.02);


// Badges
$badge-font-size:               0.75rem !default;
$badge-font-size-sm:            0.5rem !default;
$badge-font-size-lg:            .85rem !default;
$badge-size:                    1.5rem !default;
$badge-size-sm:                 1.25rem !default;
$badge-size-lg:                 1.75rem !default;

$position-values: (
  0: 0,
  50: 50%,
  90: 90%,
  100: 100%
);

// $table-cell-padding-y:                      .5rem;
// $table-cell-padding-x:                      .5rem;
// $table-cell-padding-y-sm:                   .5rem;
// $table-cell-padding-x-sm:                   .5rem;
